import { toast } from 'react-toastify';
import HttpService from '../../../../services/http';

const BoardList = ({ boardData, period, setYantra, yantra, overlay }: any) => {
    const yantraSelectHandler = async (yantra: string) => {
        try {
            await HttpService.setYantraResult({
                period,
                yantra
            });
            setYantra(yantra);
        }
        catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    return (
        <>
            {
                boardData &&
                <>
                    <div className="patient-list-area" >
                        <div className="patient-list" style={{ textAlign: "center" }}>
                            <div className="table-responsive">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">Colors</th>
                                            <th scope="col">Bet Amount</th>
                                            <th scope="col">Loss (%)</th>
                                            <th scope="col">Price Amount</th>
                                            <th scope="col">Earn Amount</th>
                                            <th scope="col">Result</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ position: "relative" }}>
                                        {
                                            boardData.gameBoard.map((combo: any, i: number) => (
                                                <tr key={i}>
                                                    <td style={{ width: "10%" }}>
                                                        <button className='color-combination' style={{ color: "black" }}>{combo.yantra}</button></td>
                                                    <td>{Number(combo.betAmount).toFixed(2)}</td>
                                                    <td>{Math.round(Number(combo.loss))}%</td>
                                                    <td>{Number(combo.lossAmount).toFixed(2)}</td>
                                                    <td>{Number(combo.earnAmount).toFixed(2)}</td>
                                                    <td style={{ width: "10%" }} >
                                                        <button
                                                            className={`${combo.yantra == yantra ? "selected-result" : "selecte-result"} ${overlay && "overlay"}`}
                                                            disabled={combo.yantra == yantra || overlay}
                                                            onClick={() => yantraSelectHandler(combo.yantra)}>
                                                            {combo.yantra == yantra ? "selected" : "select"}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default BoardList