import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import HttpService from '../../services/http';
import { Root } from '../Root'

const queriesPerPage = 12;
const status = [
    "pending",
    "success",
    "failed"
]

export const Withdraws = () => {
    const [withdraws, setWithdraws] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(0);
    const withdrawLength = withdraws.length;

    const verifyWithdraw = async (body: any, index: number) => {
        try {
            if (body.status !== "pending") {
                await HttpService.verifyWithdraw(body);

                let updatedWithdraws = [...withdraws];
                updatedWithdraws[index].status = body.status;
                setWithdraws(updatedWithdraws);

                toast.success("Withdraw verified.");
            }
        }
        catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    }

    const getTransactions = async () => {
        try {
            const data = await HttpService.getWithdrawalReqs();
            setWithdraws(data);
        }
        catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        getTransactions();
    }, []);

    return (
        <Root>
            <div className="page-wrape">
                <div className="patient-list-area">
                    <div className="container-fluid">
                        <div className="section-header">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <div className="section-title">
                                        <h2 className="title">Withrawals Requests</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="patient-list" style={{ minHeight: "750px" }}>
                            <div className="table-responsive">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">Phone No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Upi Id</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Payable Amount</th>
                                            <th scope="col">status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            withdraws.slice(page * queriesPerPage, (page + 1) * queriesPerPage).map((data: any, index: number) => (
                                                <tr key={data._id}>
                                                    <td>{data?.user?.phone}</td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>{Number(data?.user?.balance).toFixed(2)}</td>
                                                    <td>{data.upiId}</td>
                                                    <td>{data.date}</td>
                                                    <td>{data.amount}</td>
                                                    <td>{data.amount - data.fee}</td>
                                                    <td>
                                                        <Dropdown className='px-2'>
                                                            <Dropdown.Toggle className='win-per'>
                                                                {data.status}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ border: "none" }}>
                                                                {status
                                                                    .filter((it: string) => it != data.status)
                                                                    .map((it: string, i: number) =>
                                                                        <Dropdown.Item
                                                                            key={i}
                                                                            className="per"
                                                                            onClick={() => verifyWithdraw({
                                                                                withdrawId: data._id,
                                                                                userId: data.user._id,
                                                                                status: it
                                                                            }, index)}
                                                                        >{it}</Dropdown.Item>
                                                                    )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="pagination-area mt-10">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p className="shoing-reselt m-0">Showing {withdrawLength > 12 ? 12 : withdrawLength} from {withdrawLength} data</p>
                                </div>
                                <div className="col-sm-6 text-left text-sm-right">
                                    <ul className="pagination-list">
                                        {page != 0 && <li className="Previous">
                                            <a onClick={() => setPage(page - 1)}>
                                                <i className="flaticon-left-arrow"></i> Previous
                                            </a>
                                        </li>}
                                        {page <= Math.ceil((withdrawLength / queriesPerPage) - 1) && <li className={`page ${"active"}`}>
                                            <a onClick={() => setPage(page)}>{page + 1}</a>
                                        </li>}
                                        {page + 1 <= Math.ceil((withdrawLength / queriesPerPage) - 1) && <li className="page">
                                            <a onClick={() => setPage(page + 1)}>{page + 2}</a>
                                        </li>}
                                        {page + 2 <= Math.ceil((withdrawLength / queriesPerPage) - 1) && <li className="page">
                                            <a onClick={() => setPage(page + 2)}>{page + 3}</a>
                                        </li>}
                                        {page + 3 <= Math.ceil((withdrawLength / queriesPerPage) - 1) && <li className="page">
                                            <a onClick={() => setPage(page + 3)}>{page + 4}</a>
                                        </li>}
                                        {page + 4 <= Math.ceil((withdrawLength / queriesPerPage) - 1) && <li className="next">
                                            <a onClick={() => setPage(page + 1)}>
                                                Next <i className="flaticon-next"></i>
                                            </a>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    )
}
