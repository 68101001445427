import { Root } from "../Root"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import HttpService from "../../services/http";
import { toast } from "react-toastify";

const ReferEarn = () => {
    const [referEarnBody, setReferEarnBody] = useState<any>({});

    const handleSubmit = async (e: SyntheticEvent) => {
        try {
            e.preventDefault();
            const response = await HttpService.setReferEarn(referEarnBody);
            setReferEarnBody(response);
            toast.success('Refer earn updated successfully.');
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getReferEarn = async () => {
        try {
            const data = await HttpService.getReferEarn();
            setReferEarnBody(data);
        }
        catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        getReferEarn();
    }, []);

    const bodyHandler = (e: ChangeEvent<HTMLInputElement>) => {
        let body = { ...referEarnBody };
        body[e.target.name] = e.target.value;
        setReferEarnBody(body);
    }

    return (
        <Root>
            <div className="page-wrape">
                <div className="patient-list-area">
                    <div className="container-fluid">
                        <div className="section-header">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <div className="section-title">
                                        <h2 className="title">Refer Earn</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="primary-form">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="accountNo">Min Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="minAmount"
                                                placeholder="Min Amount"
                                                value={referEarnBody?.minAmount}
                                                onChange={bodyHandler}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="ifscCode">Earn Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="earnAmount"
                                                placeholder="Earn Amount"
                                                value={referEarnBody?.earnAmount}
                                                onChange={bodyHandler}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" className="form-btn">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    );
}

export default ReferEarn;