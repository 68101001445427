import { useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import HttpService from '../../../services/http';
import { gameTypes } from '../../../utils/globalConst';

const GameBoardHeader = () => {
    const [giveAwayPer, setGiveAwayPer] = useState<number>(10);

    const updatePer = async (per: number) => {
        await HttpService.updateYantraPricePercentage(per);
        setGiveAwayPer(per);
    }

    const getPercentage = async () => {
        try {
            const percentage = await HttpService.getYantraPricePercentage();
            setGiveAwayPer(percentage);
        }
        catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        getPercentage();
    }, []);

    return (
        <div className="section-header">
            <div className="section-title">
                <h2 className="title">Game Board</h2>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
            }}>
                <Dropdown>
                    <Dropdown.Toggle className='win-per'>
                        {giveAwayPer}%
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ border: "none" }}>
                    {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((x: any, i: number) =>
                            <Dropdown.Item
                                key={i}
                                eventKey={i + 1}
                                className="per"
                                onClick={() => updatePer(x)}
                            >{x}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default GameBoardHeader