import { useEffect, useState } from "react";
import BoardList from './BoardList';
import { toast } from 'react-toastify';
import CurrentPeriodInfo from './CurrentPeriodInfo';
import GameBoardHeader from './GameBoardHeader';
import HttpService from "../../../services/http";
import { gameColors } from "../../../utils/globalConst";
import initializeSocket from "../../../utils/socket";
import { Welcome } from "./Welcome";

export const GameBoard = () => {
    const [period, setPeriod] = useState<number | null>(null);
    const [gameBoardList, setGameBoardList] = useState<any>(null);
    const [slectedYantra, setSelectedYantra] = useState<string | null>(null);
    const [overlay, setOverlay] = useState<boolean>(true);

    const getCurrentPeriod = async () => {
        try {
            const boardListData = await HttpService.getCurrentEstimatedYantraResult(period!);
            setGameBoardList(boardListData);
        }
        catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    const initiateSocketEvents = () => {
        const socket = initializeSocket();
        socket.on("newYantraRecord", ((newAllGameBoardList: any) => {
            setGameBoardList(newAllGameBoardList);
        }));
    }

    useEffect(() => {
        if (period) {
            getCurrentPeriod();
        }
    }, [period]);

    useEffect(() => {
        initiateSocketEvents();
    }, []);


    const setCurrentPeriod = (currentPeriod: number) => {
        setSelectedYantra(null);
        setPeriod(currentPeriod);
    };

    const setYantra = (yantra: string) => {
        setSelectedYantra(yantra);
        let gameBoardData = { ...gameBoardList };
        gameBoardData.finalResult = yantra;
        setGameBoardList(gameBoardData);
    };

    return (
        <>
            <Welcome period={period} />
            <div className="container-fluid game-board-section">
                <GameBoardHeader/>
                <CurrentPeriodInfo
                    setCurrentPeriod={setCurrentPeriod}
                    boardResult={gameBoardList && gameBoardList.finalResult}
                    totalBet={gameBoardList && gameBoardList.totalBetAmount}
                    setOverlay={setOverlay}
                />
                {
                    gameBoardList &&
                    <BoardList
                        boardData={gameBoardList}
                        period={period}
                        setYantra={setYantra}
                        yantra={slectedYantra}
                        overlay={overlay}
                    />
                }
            </div>
        </>
    )
}
