import { Root } from "../Root"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import HttpService from "../../services/http";
import { toast } from "react-toastify";

const AppInfo = () => {
    const [appBody, setAppBody] = useState<any>({});

    const handleSubmit = async (e: SyntheticEvent) => {
        try {
            e.preventDefault();
            let formData = new FormData();

            formData.append('version', appBody.version);
            formData.append('apk', appBody.apk);
            formData.append('dmg', appBody.dmg);

            const response = await HttpService.appUpdate(formData);
            setAppBody(response);
            toast.success('updated successfully.');
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAppInfo = async () => {
        try {
            const data = await HttpService.getappInfo();
            setAppBody(data);
        }
        catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        getAppInfo();
    }, []);

    const bodyHandler = (e: ChangeEvent<HTMLInputElement>) => {
        let body = { ...appBody };
        body[e.target.name] = e.target.value;
        setAppBody(body);
    }

    const bodyImageHandler = (e: any) => {
        let body = { ...appBody };
        body[e.target.name] = e.target.files[0];
        setAppBody(body);
    };

    return (
        <Root>
            <div className="page-wrape">
                <div className="patient-list-area">
                    <div className="container-fluid">
                        <div className="section-header">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <div className="section-title">
                                        <h2 className="title">App Info</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="primary-form">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="version">Version</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="version"
                                                placeholder="version"
                                                value={appBody?.version}
                                                onChange={bodyHandler}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="apk">Apk</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="apk"
                                                onChange={bodyImageHandler}
                                                max={1}
                                                min={1}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="dmg">Dmg</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="dmg"
                                                onChange={bodyImageHandler}
                                                max={1}
                                                min={1}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" className="form-btn">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    );
}

export default AppInfo;