import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import initializeSocket from "../../../../utils/socket";

interface CurrentPeriod {
    period: number,
    startTime: number,
    endTime: number
}

const CurrentPeriodInfo = ({ setCurrentPeriod, boardResult, totalBet, setOverlay }: any) => {
    const [gameInfo, setGameInfo] = useState<CurrentPeriod | null>(null);
    const [timer, setTimer] = useState<string>("00:00");

    const getCurrentPeriod = async () => {
        try {
            const periodData: CurrentPeriod = await HttpService.getCurrentYantraPeriod();
            setGameInfo(periodData);
            setCurrentPeriod(periodData.period);
        }
        catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    const initiateSocketEvents = () => {
        const socket = initializeSocket();
        socket.on("newYantraPeriod", ((data: CurrentPeriod) => {
            setGameInfo(data);
            setCurrentPeriod(data.period);
        }));
    }

    useEffect(() => {
        getCurrentPeriod();
    }, []);

    useEffect(() => {
        initiateSocketEvents();
    }, []);

    useEffect(() => {
        let countDown: any;
        if (gameInfo) {
            let countDown = setInterval(function () {
                const now = new Date().getTime();
                const distance = gameInfo?.endTime! - now;
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setTimer(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);

                if (minutes === 0 && seconds === 30) {
                    setOverlay(false);
                }
                if (minutes === 0 && seconds === 15) {
                    setOverlay(true);
                }

                if (minutes === 0 && seconds === 1) {
                    clearInterval(countDown);
                }

                if (minutes < 0 || seconds < 0) {
                    window.location.reload();
                }
            }, 1000);
        }
        return () => {
            clearInterval(countDown);
        }
    }, [gameInfo]);

    return (
        <div className="row">
            <div className="single-welcome col-lg">
                <div className="media align-items-center">
                    <div className="media-body game-board">
                        <div>
                            <p className="m-0 game-board-text">Period</p>
                            <h3 className="counter">
                                {
                                    gameInfo ? gameInfo.period : ""
                                }
                            </h3>
                        </div>
                        <div>
                            <p className="m-0 game-board-text">Total Bet Amount</p>
                            <h3 className="counter">{totalBet ? Number(totalBet).toFixed(2) : "00:00"}</h3>
                        </div>
                        <div>
                            <p className="m-0 game-board-text">Count Down</p>
                            <h3 className="counter">{timer}</h3>
                        </div>
                        <div>
                            <p className="m-0 game-board-text">final Result</p>
                            <div className='final-yantra-result-section'>
                                {
                                    boardResult &&
                                    <>
                                        <button style={{ color: "black" }} >
                                            {boardResult}
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentPeriodInfo